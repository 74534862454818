import { Field, Form } from "react-final-form";
import { BottomBarRegistration } from "../../lib/final-form/bottom-bar-registration";
import { TopBarRegistration } from "../../lib/final-form/top-bar-registration";
import banner12 from '../../images/banner12.png';
import './registration.scss';
import { checkbox } from "../../lib/final-form/checkbox";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { useAgentStore } from "../../stores/use-agent-store";
import I18n from "../../lib/lingui/i18n";
import logoButton from "../../images/content.png"


import { RegistrationEducationTitleForm } from "../../stores/registration-store";
import { EnumItemModel } from "../../model/authentication";

export const RegistrationEducationTitle = observer(() => {
    const { registrationStore } = useAgentStore();
    let history = useHistory();
    const [subjectList, setSubjectList] = useState<Array<EnumItemModel>>([]);
    const [professionalInfo, setProfessionalInfo] = useState<Array<EnumItemModel>>([]);
    const [professionalInfoNames, setProfessionalInfoNames] = useState<Array<string>>([]);
    const [languageDirection, setLanguageDirection] = useState<string>();


    useEffect(() => {
        if (window.location.pathname.includes('he') || window.location.pathname.includes('ar')) {
            setLanguageDirection("rtl")
        }
        else {
            setLanguageDirection("lts")
        }
        if (!registrationStore.callAgentOnboarding)
            registrationStore.getCallAgentOnBoarding().then((res) => {
                if (registrationStore.callAgentOnboarding) {
                    var education = registrationStore.callAgentOnboarding?.education.slice().slice();
                    if (education) {
                        setProfessionalInfo(education);
                        var names = education.map((item) => item.name);
                        setProfessionalInfoNames(names);
                    }
                }
            });
        registrationStore.getEnumByKey('PhoneDo.CallAgent.Education').then((res) => {
            if (res) {
                setSubjectList(res.items.slice());
            }
        })
        var education = registrationStore.callAgentOnboarding?.education.slice();
        if (education) {
            setProfessionalInfo(education);
            var names = education.map((item) => item.name);
            setProfessionalInfoNames(names);
        }
    }, [registrationStore])

    async function onchange(text: EnumItemModel) {
        var inarray=false;
        for(var i=0;i<professionalInfo.length;i++)
        {
            if(professionalInfo[i].id === text.id)
                {  
                    inarray=true;
                    break;
                }
        }
        if(inarray){
            var list1 = professionalInfo.filter((item) => item.id !== text.id);
            setProfessionalInfo(list1)
            var names = list1.map((item) => item.name);
            setProfessionalInfoNames(names);
        }
        else {
            var list = professionalInfo.slice();
            list.push(text)
            setProfessionalInfo(list)
            var names1 = list.map((item) => item.name);
            setProfessionalInfoNames(names1);
        }
    }
    return (
        <div>
            <Form<RegistrationEducationTitleForm>
                initialValues={registrationStore.registrationEducationTitleForm}
                onSubmit={async (form: any) => {
                    switch (form.formButton) {
                        case 'next': {
                            if (professionalInfo.length > 0) {
                                const response = await registrationStore.updateCallAgent({ education: professionalInfo })
                                if (response && response.errors) {
                                    return response;
                                }
                                return history.push(`/${window.location.pathname.slice(1, 3)}/registration/profession`)
                            }
                            break;
                        }
                        case 'back': {
                            return history.push(`/${window.location.pathname.slice(1, 3)}/registration/certificates`)
                        }
                        case 'exit': {
                            const response = await registrationStore.updateCallAgent({ education: professionalInfo })
                            if (response && response.errors) {
                                return response;
                            }
                            return history.push(`/${window.location.pathname.slice(1, 3)}`)
                        }
                    }
                }} >
                {({ submitError, handleSubmit, form }) => (
                    <form onSubmit={handleSubmit} className={languageDirection === "rtl" ? "registrationBodyRtl" : "registrationBody"}>
                        <img src={banner12} className="banner" id="banner12" alt={'banner12'} />
                        <img className={languageDirection === "rtl" ? "logoButtonRtl" : "logoButton"} src={logoButton} alt={'logoButton'} onClick={() => { history.push('/') }} />
                        <div className={languageDirection === "rtl" ? "formSideRtl" : "formSide"}>
                            <TopBarRegistration title="Professional information" subTitle="Education title" withMenu={true} withExit={true} clickExit={() => form.change('formButton', 'exit')} />
                            <div className={languageDirection === "rtl" ? "dataAreaWide formSideRtl" : "dataAreaWide formSide"} >
                                <div className="header"><I18n t="Add education title" /></div>
                                <div className="subHeader"><I18n t="You can select up to 3 education title" /></div>
                                <div className="flexForm flexFormScroll">
                                    <div className={languageDirection === "rtl" ? "marginSide marginLeftRtl" : "marginSide"}>
                                        {subjectList.map((item, index) => (
                                            index < subjectList.length / 2 ? <div key={item.id}>
                                                <Field disabled={professionalInfo.length === 3 && professionalInfoNames.indexOf(item.name) === -1 && item.name !== 'None'} checked={professionalInfoNames.indexOf(item.name) !== -1} name='aaa' id={item.id} inputType={"checkbox"} inputClass="smallInputText checkboxPadding" text={item.name} onchange={() => onchange(item)} component={checkbox} type="checkbox" />
                                            </div> : null
                                        ))}
                                    </div>
                                    <div className="itemRight">
                                        {subjectList.map((item, index) => (
                                            index > subjectList.length / 2 ? <div key={item.id}>
                                                <Field disabled={professionalInfo.length === 3 && professionalInfoNames.indexOf(item.name) === -1 && item.name !== 'None'} checked={professionalInfoNames.indexOf(item.name) !== -1} name='aaa' id={item.id} inputType={"checkbox"} inputClass="smallInputText checkboxPadding" text={item.name} onchange={() => onchange(item)} component={checkbox} type="checkbox" />
                                            </div> : null
                                        ))}
                                    </div>
                                </div>
                                {submitError && <div className="submitError"><I18n t={submitError} /></div>}
                            </div>
                            <BottomBarRegistration buttonNextapprove={professionalInfo.length > 0 ? "approve" : null} withBack={true} clickBack={() => form.change('formButton', 'back')} clickNext={() => form.change('formButton', 'next')} />
                        </div>
                    </form>
                )}
            </Form>

        </div >
    );
});


