import { FieldRenderProps } from "react-final-form";
import '../lib.scss'
import React from "react";
import I18n from "../lingui/i18n";


type Props<T> = FieldRenderProps<T, HTMLInputElement>;

export const checkbox = <T extends string | number>(props: Props<T>) => {
    const { meta, input } = props;
    return <>
        <div className={props.inputClass ? props.inputClass : "checkbox"}>
            <div className="flex ">
                <div className="checkboxItem">
                    {props.imgStyle ?
                        <div style={{ backgroundColor: props.imgStyle }} className="borderMedia">
                            <img src={props.img} alt="media" id="" className="media" />
                        </div>
                        : <div></div>}
                    <div className="mediaText"><I18n t={props.text} /> </div>
                </div>
                <div className="checkboxItemRight">
                    <input
                        id={props.id}
                        className={"checkbox"}
                        accept={props.inputAccept && props.inputAccept}
                        type={props.inputType}
                        // value={input.value}
                        checked={props.checked}
                        onChange={props.onchange ? props.onchange : input.onChange}
                        disabled={props.disabled}
                        placeholder={props.placeholder}
                        style={props.style}
                        autoFocus={props.autoFocus}
                    />
                </div>
            </div>

        </div>
        {(meta.touched && meta.error) || (meta.submitError &&
            <div className="submitError">{(meta.error) || (meta.submitError)}</div>)}
    </>
}