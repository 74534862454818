import axios, { AxiosInstance } from 'axios';
import { CallAgentOnboardingData } from '../model/authentication';
import Cookies from 'universal-cookie';
import { v4 as uuidv4 } from 'uuid';
export const CALLAGENT_TOKEN_KEY = 'calAgentTokenKey';

const apiURL = process.env.REACT_APP_API_URL;
const interviewURL = process.env.REACT_APP_INTERVIEW_API_URL;

const cookies = new Cookies();
export class ApiClient {
    public agentTokenKey: string = '';
    constructor(private _client: AxiosInstance) {
        this._client = axios;
    }
    callAgentSignUpTokenCreate(partiallyAuthanticatedJwtToken: string) {
        this.agentTokenKey = partiallyAuthanticatedJwtToken;
        if (cookies.get('phone.do_cookies') === 'true') {
            cookies.set(CALLAGENT_TOKEN_KEY, partiallyAuthanticatedJwtToken);
        }
    }

    requestTokenCodeResponse(token: String) {
        return this._client
            .put(`${apiURL}/api/signup_token_codes/${token}/request_code`, {}, {
                headers: {
                    'accept': 'application/ld+json',
                    'content-type': 'application/ld+json',
                }
            }
            );
    }

    requestTokenResponse(data: any) {
        return this._client
            .post(`${apiURL}/api/signup_token_codes`,
                JSON.stringify(data),
                {
                    headers: {
                        'accept': 'application/ld+json',
                        'content-type': 'application/ld+json',
                    }
                }
            );
    }

    smsVerificationCheck(token: String, code: String) {
        return this._client
            .put(`${apiURL}/api/signup_token_codes/${token}/check_code/${code}`, {
                headers: {
                    'accept': 'application/ld+json',
                    'content-type': 'application/ld+json',
                }
            })
    }

    signupAgent(body: object) {
        return this._client
            .post(`${apiURL}/api/call_agents/signup`, body, {
                headers: {
                    'accept': 'application/ld+json',
                    'content-type': 'application/ld+json',
                }
            })
            .then((response) => response.data)
    }
    signupAgentJwt(body: object) {
        return this._client
            .post(`${apiURL}/api/call_agents/signup_web`, body, {
                headers: {
                    'accept': 'application/ld+json',
                    'content-type': 'application/ld+json',
                }
            })
            .then((response) => response.data)
    }
    async saveImageProfile(agentId: string | undefined, file: File, token: string) {
        let data = new FormData();

        data.append("file", new Blob([file]), `avatar-${agentId}.${file.type}`,
        );
        return this._client

            .post(`${apiURL}/api/call_agents/${agentId}/upload_avatar`, data, {
                headers: {
                    'accept': 'application/ld+json',
                    'content-type': 'application/ld+json',
                    'authorization': `bearer ${token}`,
                }
            }).then((response) => response.data)
    }

    async getCallAgentOnBoarding(agentId: string, token: string) {
        return this._client.get<CallAgentOnboardingData>(`${apiURL}/api/call_agents/${agentId}`, {
            headers: {
                'accept': 'application/ld+json',
                'authorization': `Bearer ${token}`
            }
        }).then((response) => response.data)
    }

    updateCallAgentLanguages(agentId: String | undefined, languagesData: Object[], token: string | null) {
        var data = JSON.stringify({ 'languages': languagesData })
        return this._client
            .put(`${apiURL}/api/call_agent/${agentId}/languages_list`, data, {
                headers: {
                    'accept': 'application/ld+json',
                    'content-type': 'application/ld+json',
                    'authorization': `Bearer ${token}`,
                }
            }).then((response) => response.data)
    }

    async getCallAgentLanguages(agentId: string | undefined, token: string | null) {
        return this._client.get(`${apiURL}/api/call_agents/${agentId}/languages`, {
            headers: {
                'accept': 'application/ld+json',
                'content-type': 'application/ld+json',
                'authorization': `Bearer ${token}`,
            }
        }).then((response) => response.data['hydra:member'])
    }

    updateCallAgent(agentId: String | undefined, data: object, token: string | null) {
        var dataJson = JSON.stringify(data)
        return this._client
            .put(`${apiURL}/api/call_agents/${agentId}`, dataJson, {
                headers: {
                    'accept': 'application/ld+json',
                    'content-type': 'application/ld+json',
                    'authorization': `Bearer ${token}`,
                }
            }).then((response) => response.data)
    }

    // fetchUserInfo(userId: string | undefined, token: string | null) {
    //     return this._client.get(
    //         `${apiURL}/api/call_agents/${userId}`, {
    //         headers: {
    //             'accept': 'application/ld+json',
    //             'content-type': 'application/ld+json',
    //             'authorization': `Bearer ${token}`,
    //         }
    //     }
    //     );
    // }

    async getEnumByKey(key: string) {
        return this._client.get(`${apiURL}/api/selection_lists?key=${key}`, {
            headers: {
                'accept': 'application/ld+json',
                'content-type': 'application/ld+json',
            }
        }).then((response) => response.data['hydra:member'])
    }

    fetchLanguages() {
        return this._client.get(`${apiURL}/api/languages`, {
            headers: {
                'accept': 'application/ld+json',
                'content-type': 'application/ld+json',
            }
        }).then((response) => response.data['hydra:member']);
    }

    async fetchCertificationByUserId(userId: string | undefined, token: string | null) {
        return this._client.get(
            `${apiURL}/api/call_agent_certifications?callAgent=${userId}`, {
            headers: {
                'accept': 'application/ld+json',
                'content-type': 'application/ld+json',
                'authorization': `Bearer ${token}`,
            }
        }
        ).then((response) => response.data);
    }

    async uploadCertification(userId: string | undefined, file: File, type: any | undefined | null, expirationDate: any | undefined | null, jurisdiction: any | undefined | null, professionId: any | undefined | null, token: string | null) {
        let data = new FormData();
        var blob = new Blob([file], { type: `${type}`, });
        data.append("file", blob,
            `certification-${userId}.${blob.type.split('/').pop()}`,
        );
        data.append("id", uuidv4());
        data.append("professionId", professionId);
        data.append("jurisdiction", jurisdiction);
        data.append("expirationDate", expirationDate != null
            ? expirationDate
            : null);
        return this._client
            .post(`${apiURL}/api/call_agents/${userId}/update_certification`, data, {
                headers: {
                    'accept': 'application/ld+json',
                    'authorization': `Bearer ${token}`,
                }
            }).then((response) => response.data)
    }

    async updateCertification(userId: string | undefined, certificationId: string | undefined, file: File, type: any, expirationDate: any | undefined | null, jurisdiction: any | undefined | null, professionId: any | undefined | null, token: string | null) {
        let data = new FormData();
        var blob = new Blob([file], { type: `${type}`, });
        data.append("file", blob,
            `certification-${userId}.${blob.type.split('/').pop()}`,
        );
        data.append("professionId", professionId);
        data.append("jurisdiction", jurisdiction);
        data.append("expirationDate", expirationDate ?? null);
        return this._client
            .post(`${apiURL}/api/cal_agent_certifications/${certificationId}/update`, data, {
                headers: {
                    'accept': 'application/ld+json',
                    'authorization': `Bearer ${token}`,
                }
            }).then((response) => response.data)
    }
    async uploadInterviewData(body: object) {
        return this._client
            .post(`${interviewURL}/api/upload`, body, {
                headers: {
                    "content-type": "application/json",
                },
            })
            .then((response) => response.data);
    }

    async uploadVideo(uploadURL: string, file: any) {
        return this._client
            .put(uploadURL, file, {
                headers: {
                    "content-type": "application/octet-stream",
                },
            })

            .then((response) => console.log(response))

            .catch((error) => {
                console.log("error: " + error);
            });
    }

    async signalDone(body: object, interviewUuid: String) {



        return this._client

            .post(`${interviewURL}/api/interview/${interviewUuid}`, body, {
                headers: {
                    "content-type": "application/json",
                },
            })
            .then((response) => response.data);

    }

    async updateInterviewDate(agentId: string, data: object, token: string | null) {
        return this._client
            .put(`${apiURL}/api/call_agents/${agentId}/update_interview_date`, data, {
                headers: {
                    'accept': 'application/ld+json',
                    'content-type': 'application/ld+json',
                    'authorization': `Bearer ${token}`,
                },
            }).then((response) => response.data);
    }
}
