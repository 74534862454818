import { Field, Form } from "react-final-form";
import { InputField } from "../../lib/final-form/input-field";
import { RegistrationPhoneForm } from "../../stores/registration-store";
import { useHistory } from "react-router-dom";
import './registration.scss'
import banner1 from '../../images/banner1.png';
import dots2 from "../../images/dots2.svg"
import { observer } from "mobx-react";
import { useAgentStore } from "../../stores/use-agent-store";
import I18n from "../../lib/lingui/i18n";
import { countryCodeList } from "../../globals/professional-information";
import { useEffect, useState } from "react";
import { InputSelectCountry } from "../../lib/final-form/input-selectCountry";
import logoButton from "../../images/content.png"
import Cookies from 'universal-cookie';
import logoPhonedo from "../../images/logoPhonedo.png"

const cookies = new Cookies();

export const RegistrationPhone = observer((props: any) => {
    const { registrationStore } = useAgentStore();
    var responseErr = '';
    let history = useHistory();
    const { location } = props;
    const [languageDirection, setLanguageDirection] = useState<string>();

    useEffect(() => {
        if (window.location.pathname.includes('he') || window.location.pathname.includes('ar')) {
            setLanguageDirection("rtl")
        }
        else {
            setLanguageDirection("lts")
        }
    }, []);
    return (
        <div>
            <Form<RegistrationPhoneForm>
                initialValues={registrationStore.registrationPhoneForm}
                onSubmit={async (form: any) => {
                    if (form.formButton === 'send') {
                        var phoneNumber = form.countryCode.value + form.telephone;
                        const response = await registrationStore.submitRegistrationPhoneForm(
                            phoneNumber
                        );
                        if (response) {
                            if (response.errors === null) {
                                if (cookies.get('phone.do_cookies') === 'true') {
                                    cookies.set("phoneNumber", phoneNumber)
                                }
                                return history.push(`/${window.location.pathname.slice(1, 3)}/registration/verify-sms`)
                            } else if (response.telephone !== null) {
                                responseErr = response.telephone;
                            } else {
                                responseErr = response["FINAL_FORM/form-error"]?.replace(".", "");
                            }
                            return response;
                        }
                    }
                }} >
                {({ submitError, handleSubmit, values, form }) => (
                    <form onSubmit={handleSubmit} className={languageDirection === "rtl" ? "registrationBodyRtl" : "registrationBody"}>
                        <img src={banner1} className="banner" id="banner1" alt={'banner1'} />
                        <img className={languageDirection === "rtl" ? "logoButtonRtl" : "logoButton"} src={logoButton} alt={'logoButton'} onClick={() => { history.push('/') }} />
                        <img className={languageDirection === "rtl" ? "rtllogoPhonedo logoPhonedo" : "logoPhonedo"} src={logoPhonedo} alt={logoPhonedo} onClick={() => { history.push('/') }} />
                        <div className={languageDirection === "rtl" ? "dataArea formSideRtl" : "dataArea formSide"}>
                            <img className={languageDirection === "rtl" ? "dots2 dots2Rtl" : "dots2"} src={dots2} alt={dots2} />
                            <div className="bigHeader"><I18n t="Verify your phone" /></div>
                            <div className="subHeader"><I18n t="We will send you an SMS to verifyed your phone number" /></div>
                            <div  >
                                <Field name={"countryCode"} options={countryCodeList} placeholder={I18n.getTranslation(location, "Select country code")} component={InputSelectCountry} />
                            </div>
                            <div>
                                <Field name={"telephone"} inputType={"text"} placeholder={I18n.getTranslation(location, "Phone Number")} component={InputField} />
                            </div>
                            {submitError && <div><I18n t={submitError?.replace(".", "")} /></div>}
                            {responseErr === "This value is already used." &&
                                <div><I18n t="Do you want to end the registration?" /><span className="inputGreen"> <I18n t="Click here" /></span></div>}
                            <button className={'buttonCreate'} id={values.telephone && values.countryCode ? "buttonCreateApprove" : "buttonCreateDisable"}
                             onClick={() => { values.telephone && values.countryCode ? form.change('formButton', 'send') : form.change('formButton', null) }}
                              ><I18n t="Send me a code" /></button>
                        </div>
                    </form>
                )}

            </Form>

        </div >
    );
});

