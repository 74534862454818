import { createContext, useContext } from 'react';
import { AgentRootStore } from './agent-root-store';
import { assert } from 'ts-essentials';

export const StoreContext = createContext<AgentRootStore | null>(null);

export const useAgentStore = () => {
  const context = useContext(StoreContext);
  assert(context);
  return context;
};
