import { Field, Form } from "react-final-form";
import { BottomBarRegistration } from "../../lib/final-form/bottom-bar-registration";
import { TopBarRegistration } from "../../lib/final-form/top-bar-registration";
import { RegistrationShortDescriptionForm } from "../../stores/registration-store";
import banner13 from '../../images/banner13.png';
import { observer } from "mobx-react";
import './registration.scss'
import { useAgentStore } from "../../stores/use-agent-store";
import { useHistory } from "react-router-dom";
import { InputTextArea } from "../../lib/final-form/input-textarea";
import I18n from "../../lib/lingui/i18n";
import logoButton from "../../images/content.png"


import { useEffect, useState } from "react";
import { InputSearchSelect } from "../../lib/final-form/select-search";
import { Divider } from "@material-ui/core";
import { RadioButton } from "../../lib/final-form/radio-button";

export const RegistrationShortDescription = observer((props: any) => {
    const { registrationStore } = useAgentStore();
    const { location } = props;
    let history = useHistory();
    const [languageDirection, setLanguageDirection] = useState<string>();
    const [religionList, setReligionList] = useState<any>([])
    const [religionLevelList, setReligionLevelList] = useState<any>([])
    const [selectedReligionLevel, setSelectedReligionLevel] = useState<any>(null)


    useEffect(() => {
        if (!registrationStore.callAgentOnboarding) {
            registrationStore.getCallAgentOnBoarding().then((res) => {
                if (registrationStore.callAgentOnboarding?.religionLevel) {
                    setSelectedReligionLevel(registrationStore.callAgentOnboarding.religionLevel.id)
                }
            })
        } else if (registrationStore.callAgentOnboarding.religionLevel) {
            setSelectedReligionLevel(registrationStore.callAgentOnboarding.religionLevel.id)
        }

        if (religionList.length === 0) {
            registrationStore.getEnumByKey('PhoneDo.Religions').then((res) => {
                if (res) {
                    let religions: { label: string, value: string }[] = [];
                    registrationStore.religionsList = res.items;
                    registrationStore.religionsList?.reduce((obj, religion) => {
                        religions.push({
                            label: religion.name,
                            value: religion.id,
                        });
                        return {}
                    }, {});
                    setReligionList(religions);
                }
            })
        }
        if (religionLevelList.length === 0) {
            registrationStore.getEnumByKey('PhoneDo.ReligionsLevel').then((res) => {
                if (res) {
                    let religionsLevel: { label: string, value: string }[] = [];
                    registrationStore.religionsLevelList = res.items;
                    registrationStore.religionsLevelList?.reduce((obj, religionLevel) => {
                        religionsLevel.push({
                            label: religionLevel.name,
                            value: religionLevel.id,
                        });
                        return {}
                    }, {});
                    setReligionLevelList(religionsLevel);
                }
            })
        }
        if (window.location.pathname.includes('he') || window.location.pathname.includes('ar')) {
            setLanguageDirection("rtl")
        }
        else {
            setLanguageDirection("lts")
        }
    }, [registrationStore, religionLevelList, religionList])



    return (
        <div>
            <Form<RegistrationShortDescriptionForm>
                initialValues={registrationStore.registrationShortDescriptionForm}
                onSubmit={async (form: any) => {
                    switch (form.formButton) {
                        case 'next': {
                            var data = {
                                description: form.description,
                                religion: typeof (form.religion) === 'object' ? form.religion.value : form.religion,
                                religionLevel: selectedReligionLevel,
                            }

                            const response = await registrationStore.updateCallAgent(data);
                            if (response && response.errors) {
                                return response;
                            }
                            return history.push(`/${window.location.pathname.slice(1, 3)}/registration/language`)
                        }
                        case 'back': {
                            return history.push(`/${window.location.pathname.slice(1, 3)}/registration/location`)
                        }
                        case 'exit': {
                            var data1 = {
                                description: form.description,
                                religion: typeof (form.religion) === 'object' ? form.religion.value : form.religion,
                                religionLevel: selectedReligionLevel,
                            }
                            const response = await registrationStore.updateCallAgent(data1);
                            if (response && response.errors) {
                                return response;
                            }
                            return history.push(`/${window.location.pathname.slice(1, 3)}`)
                        }
                    }
                }} >
                {({ submitError, handleSubmit, form, values }) => (
                    <form onSubmit={handleSubmit} className={languageDirection === "rtl" ? "registrationBodyRtl" : "registrationBody"}>
                        <img src={banner13} className="banner" id="banner5" alt={'banner5'} />
                        <img className={languageDirection === "rtl" ? "logoButtonRtl" : "logoButton"} src={logoButton} alt={'logoButton'} onClick={() => { history.push('/') }} />

                        <div className={languageDirection === "rtl" ? "formSideRtl" : "formSide"}>
                            <TopBarRegistration title="Personal information" subTitle="A short description" withMenu={true} withExit={true} clickExit={() => form.change('formButton', 'exit')} />
                            <div className="dataAreaWide">
                                <div className="header descriptionHeader"><I18n t="Add a short description" /></div>
                                <div className="descriptionSubHeader"><I18n t="Tell us what else you want us to know about you" /></div>
                                <div className="flexForm">
                                    <div className="item">
                                        <Field name={"description"} inputType={"text"} placeholder={I18n.getTranslation(location, "Description")} inputClass="bigInputText" component={InputTextArea} />
                                    </div>

                                </div>
                                <div>
                                    <div className="header descriptionHeader"><I18n t="Choose your religion" /></div>
                                    <div className="descriptionSubHeader"><I18n t="This is an optional Please select for us what religion you are (or if you are not of a particular religion)" /></div>
                                    <Field name={"religion"} placeholder={I18n.getTranslation(location, "My religion")} options={religionList} menuClassName={"countrySelect"} inputClass={"smallInputText"} component={InputSearchSelect} />
                                    <br />
                                    <Divider />
                                    {values.religion !== null && values.religion !== undefined && values.religion !== '' ? <Field name={"religionLevel"} radioName={"religionLevel"} options={religionLevelList} component={RadioButton} selectedItem={selectedReligionLevel} onValueChange={(event: React.ChangeEvent<HTMLInputElement>) => { setSelectedReligionLevel(event.target.value) }} /> : <></>}
                                </div>
                                {submitError && <div className="submitError"><I18n t={submitError} /></div>}
                            </div>
                            <BottomBarRegistration buttonNextapprove={values.description  ? "approve" : null} withBack={true} clickBack={() => form.change('formButton', 'back')} clickNext={() => form.change('formButton', 'next')} />
                        </div>
                    </form>
                )}

            </Form>

        </div >
    );
});