import { Form } from "react-final-form";
import { TopBarRegistration } from "../../lib/final-form/top-bar-registration";
import './registration.scss';
import SavedIcon from "../../images/saved-icon.svg";
import { observer } from "mobx-react";
import banner11 from '../../images/banner11.png';
import { BottomBarRegistration } from "../../lib/final-form/bottom-bar-registration";
import dots2 from "../../images/dots2.svg"
import I18n from "../../lib/lingui/i18n";
import { useHistory } from "react-router-dom";
import logoButton from "../../images/content.png"


import { useEffect, useState } from "react";
import { useAgentStore } from "../../stores/use-agent-store";

const existingLanguages =['english','spanish','hebrew','arabic','greek','german','french'];
export const RegistrationCongratulations = observer((props: any) => {
    const [languageDirection, setLanguageDirection] = useState<string>();
    const [spokenLanguage, setSpokenLanguage] = useState<string>();
    const { location } = props;
    let history = useHistory();
    const { registrationStore } = useAgentStore();

    useEffect(() => {
        if (window.location.pathname.includes('he') || window.location.pathname.includes('ar')) {
            setLanguageDirection("rtl")
        }
        else {
            setLanguageDirection("lts")
        }
    }, []);

    useEffect(() => {
        if (!registrationStore.callAgentOnboarding)
            registrationStore.getCallAgentOnBoarding()
                .then(() => {
                    var languages1 = registrationStore.callAgentOnboarding?.languages;
                    if (languages1 && languages1.length > 0) {
                        languages1.forEach((item) => {
                        registrationStore.spokenLanguage = item.languageTitle.trim().toLowerCase();
                        setSpokenLanguage(item.languageTitle.trim().toLowerCase().toString());

                        })
                    }
                })

    }, [registrationStore])

    function checkSpokenLanguage(){
        if(spokenLanguage&& existingLanguages.includes(spokenLanguage))
        return true;
    }
    return (
        <div>
            <Form
                onSubmit={async (form: any) => { }} >
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className={languageDirection === "rtl" ? "registrationBodyRtl" : "registrationBody"}>
                        <img src={banner11} className="banner" id="banner11" alt={'banner11'} />
                        <img className={languageDirection === "rtl" ? "logoButtonRtl" : "logoButton"} src={logoButton} alt={'logoButton'} onClick={() => { history.push('/') }} />
                        <div className="flexFormArea">
                            <TopBarRegistration withMenu={true} title="Personal information" />
                            <div className={languageDirection === "rtl" ? "dataAreaCenRtl formSideRtl" : "dataAreaCenRtl formSide"}>
                                <img className="dots2-small" src={dots2} alt={dots2} />
                                <div style={{ textAlign: "center", paddingTop: "10vh" }}><span className="savedCircle"><img src={SavedIcon} alt="save" /></span></div>
                                <br />
                                <div className="bigHeader paddingTitle2"><I18n t="Congratulations!!!" /></div>
                                {/* <div className="subHeader" style={{ textAlign: "center" }}><I18n t="You are about to join the phonedo family soon" /></div> */}
                                <div className="subHeader" style={{ textAlign: "center",color: 'black',width: '100%' }}><I18n t="The PhoneDo registration process has been successfully completed! So, what is the next step and how do you start receiving calls?" /><br /></div>
                                <div className="subHeader" style={{ textAlign: "center",color: 'black',width: '100%' }}><I18n t="1 Installation - download the PhoneDo application and install it on your mobile" /><br /></div>
                                <div className="subHeader" style={{ textAlign: "center",width: '95%',color: 'black' }}><I18n t="2 Training - we emailed you a PDF file Please confirm that you have read it so that we can schedule simulations calls and coordinate a date for the training webinar" /><br /></div>
                                <div className="" style={{ textAlign: "center" ,color: 'black',width: '100%'}}><I18n t="We are here to assist with any questions" /><br /></div>
                                <div className="" style={{ textAlign: "center",color: 'black',width: '100%' }}><I18n t="Email" />: support@phone.do<br /></div>




                            </div>
                            {checkSpokenLanguage()? <BottomBarRegistration buttonNextapprove="approve" nextTitle={I18n.getTranslation(location, "Next")} clickNext={() => history.push(`/${window.location.pathname.slice(
                                1,
                                3
                            )}/registration/pre-interview`)
                            } />
                                : <BottomBarRegistration buttonNextapprove="approve" nextTitle={I18n.getTranslation(location, "Exit")} clickNext={() => history.push('/')} />}
                        </div>
                    </form>
                )}

            </Form>

        </div >
    );
});

