import { Field, Form } from "react-final-form";
import { BottomBarRegistration } from "../../lib/final-form/bottom-bar-registration";
import { InputField } from "../../lib/final-form/input-field";
import { InputSelect } from "../../lib/final-form/input-select";
import { InputSearchSelect } from "../../lib/final-form/select-search";
import { TopBarRegistration } from "../../lib/final-form/top-bar-registration";
import { RegistrationAboutYouForm } from "../../stores/registration-store";
import { observer } from "mobx-react";
import banner4 from '../../images/banner4.png';
import './registration.scss';
import { useAgentStore } from "../../stores/use-agent-store";
import { useEffect, useState } from "react";
import { FaCircle, FaCamera, FaUser, FaEnvelope, FaVenusMars, FaGlobeAfrica, FaRegCalendarAlt } from 'react-icons/fa';
import { useHistory } from "react-router-dom";
import I18n from "../../lib/lingui/i18n";
import logoButton from "../../images/content.png"


var isoCountriesLanguages = require('iso-countries-languages');


export const RegistrationAboutYou = observer((props: any) => {
    const { registrationStore } = useAgentStore();
    const { location } = props;
    const genderList = [{ label: <I18n t="Male" />, value: 'male' }, { label: <I18n t="Female" />, value: 'female' }];
    const countryList = isoCountriesLanguages.getCountries(window.location.pathname.substring(1, 3));
    const countryValueList: string[] = Object.values(countryList);
    const countryKeysList = Object.keys(countryList);
    let countryList2: { label: string, value: string }[] = [];
    var responseErr = '';

    countryValueList.reduce((obj, item) => {
        countryList2.push({
            label: item,
            value: item,
        });
        return {}
    }, {});
    let history = useHistory();
    const optionLeft: { id: number, name: string, "disabled": string, inputType: string, placeholder: string, inputClass: string, icon: any }[] = [
        { "id": 1, "name": "firstName", "disabled": "disabled", "inputType": "text", "placeholder": "First name", "inputClass": "smallInputText", "icon": <FaUser />, },
        { "id": 2, "name": "email", "disabled": "disabled", "inputType": "text", "placeholder": "Email address", "inputClass": "smallInputText", "icon": <FaEnvelope />, },
    ];
    const optionRight: {
        id: number, name: string, "disabled": string, inputType: string, placeholder: string, inputClass: string, icon: any
    }[] = [
            { "id": 4, "name": "lastName", "disabled": "disabled", "inputType": "text", "placeholder": "Last name", "inputClass": "smallInputText", "icon": <FaUser />, },
            { "id": 5, "name": "birthDate", "disabled": "", "inputType": "date", "placeholder": "Date of birth", "inputClass": "smallInputText", "icon": <FaRegCalendarAlt />, },
        ];
    const selectLeft: { id: number, name: string, placeholder: string, inputClass: string, options: { label: JSX.Element, value: string }[], menuClassName: string, icon: any }[] = [
        { "id": 3, "name": "gender", "placeholder": "My gender", "inputClass": "smallInputText", options: genderList, "menuClassName": "countrySelect", "icon": <FaVenusMars />, },
    ];
    const selectRight: {
        id: number, name: string, placeholder: string, inputClass: string, options: { label: string, value: string }[], menuClassName: string, icon: any
    }[] = [
            { "id": 6, "name": "countryBirth", "placeholder": "Country of Birth", "inputClass": "smallInputText", options: countryList2, "menuClassName": "countrySelect", "icon": <FaGlobeAfrica />, },
        ];
    const [image, setImage] = useState({ preview: "", raw: "" });
    const [imagefile, setImageFile] = useState<File>();
    const [languageDirection, setLanguageDirection] = useState<string>();

    useEffect(() => {
        if (window.location.pathname.includes('he') || window.location.pathname.includes('ar')) {
            setLanguageDirection("rtl")
        }
        else {
            setLanguageDirection("lts")
        }
    }, []);

    const handleChange = (e: any) => {
        if (e.target.files.length) {
            var file = (e.target.files[0]);
            setImageFile(file);

            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0]
            });
        }
    };

    function checkFields(form: any) {
        if (form.gender && form.birthDate && form.countryBirth) {
            return "approve"
        }
        return null;
    }

    useEffect(() => {
        if (!registrationStore.callAgentOnboarding)
            registrationStore.getCallAgentOnBoarding()
    }, [registrationStore])
    return (
        <div>
            <Form<RegistrationAboutYouForm>
                initialValues={registrationStore.registrationAboutYouForm}
                onSubmit={async (form: any) => {
                    var today=new Date();
                    if(form.birthDate >= today)
                    {
                        responseErr= 'The date of birth must be greater than today' ;
                        return;
                    }
                    switch (form.formButton) {
                        case 'next': {
                            await registrationStore.saveImageProfile(imagefile);
                            var i = countryValueList.indexOf(form.countryBirth.value)
                            var data = {
                                "firstName": form.firstName,
                                "lastName": form.lastName,
                                "email": form.email,
                                "countryBirth": isoCountriesLanguages.getCountry('en', countryKeysList[i]),
                                "gender": form.gender.value,
                                "birthDate": form.birthDate,
                            }
                            const response = await registrationStore.updateCallAgent(data);
                            if (response && response.errors) {
                                return response;
                            }
                            return history.push(`/${window.location.pathname.slice(1, 3)}/registration/location`)
                        }
                        case 'back': {
                            return history.push(`/${window.location.pathname.slice(1, 3)}/registration/agent-type`)
                        }
                        case 'exit': {
                            await registrationStore.saveImageProfile(imagefile);
                            var index = countryValueList.indexOf(form.countryBirth.value)
                            var data1 = {
                                "firstName": form.firstName,
                                "lastName": form.lastName,
                                "email": form.email,
                                "countryBirth": isoCountriesLanguages.getCountry('en', countryKeysList[index]),
                                "gender": form.gender.value,
                                "birthDate": form.birthDate,
                            }
                            const response = await registrationStore.updateCallAgent(data1);
                            if (response && response.errors) {
                                return response.errors;
                            }
                            return history.push(`/${window.location.pathname.slice(1, 3)}`)
                        }
                    }
                }} >
                {({ submitError, handleSubmit, form, values }) => (
                    
                    <form onSubmit={handleSubmit} className={languageDirection === "rtl" ? "registrationBodyRtl" : "registrationBody"}>
                        <img src={banner4} className="banner" id="banner4" alt={'banner4'} />
                        <img className={languageDirection === "rtl" ? "logoButtonRtl" : "logoButton"} src={logoButton} alt={'logoButton'} onClick={() => { history.push('/') }} />
                        {/* <img className={languageDirection === "rtl" ? "rtllogoPhonedo logoPhonedo" : "logoPhonedo"} src={logoPhonedo} alt={logoPhonedo} onClick={() => { history.push('/') }} /> */}
                        <div className={languageDirection === "rtl" ? "formSideRtl" : "formSide"}>
                            <TopBarRegistration title="Personal information" subTitle="About you" withMenu={true} withExit={true} clickExit={() => form.change('formButton', 'exit')} />
                            <div className="dataAreaWide">
                                <div className="header"><I18n t="About you" /></div>
                                <div style={{ display: "flex" }}>
                                    <div style={{ height: '93px', width: '99px', }}>
                                        <label htmlFor="upload-button">
                                            {image.preview ? (
                                                <div style={{ display: 'grid', gridTemplateColumns: '1fr', gridTemplateRows: '1fr', gridArea: 'overlap', }} >
                                                    <img className="faCircle-border-icon" src={image.preview} alt="uploadImg" style={{ color: "#F6F5FB", height: '90px', width: '90px', gridArea: 'overlap' }} />
                                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr', gridTemplateRows: '1fr', gridArea: 'overlap', }}>
                                                        <FaCircle className="faRegCircle-border-icon" />
                                                        <FaCamera style={{ color: "#64BEE1", gridColumn: '1', gridRow: '1', gridArea: 'overlap', alignSelf: 'center', justifySelf: 'center', }} />
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr', gridTemplateRows: '1fr', gridArea: 'overlap', }}>
                                                        <FaCircle className="faCircle-border-icon" style={{ color: "#F6F5FB", fontSize: '90px', backgroundImage: 'none', gridArea: 'overlap' }} />
                                                        <div style={{ display: 'grid', gridTemplateColumns: '1fr', gridTemplateRows: '1fr', gridArea: 'overlap', }}>
                                                            <FaCircle className="faRegCircle-border-icon" />
                                                            <FaCamera style={{ color: "#64BEE1", gridColumn: '1', gridRow: '1', gridArea: 'overlap', alignSelf: 'center', justifySelf: 'center', }} />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </label>
                                        <input
                                            type="file"
                                            id="upload-button"
                                            style={{ display: "none" }}
                                            onChange={handleChange}
                                            accept="image/png, image/png, image/jpg"
                                        />
                                    </div>
                                    <div style={{ paddingLeft: "5px", paddingTop: "30px" }} className="topBarRegistrationTitle"><I18n t="upload Your Profile Image" /></div>
                                </div>
                                <div style={{ height: "32px" }}></div>
                                <div className="flexForm">
                                    <div >
                                        <div className={languageDirection === "rtl" ? "marginLeftRtl" : ""}>
                                            {optionLeft.map((item, index) => (
                                                <Field key={item.id} name={item.name} disabled={item.disabled} inputType={item.inputType} placeholder={I18n.getTranslation(location, item.placeholder)} inputClass={item.inputClass} icon={item.icon} component={InputField} />
                                            ))}
                                        </div>
                                        <div>
                                        </div>
                                        <div>
                                            {selectLeft.map((item, index) => (
                                                <Field key={item.id} name={item.name} placeholder={I18n.getTranslation(location, item.placeholder)} options={item.options} menuClassName={item.menuClassName} inputClass={item.inputClass} icon={item.icon} component={InputSelect} />
                                            ))}
                                        </div>
                                    </div>
                                    <div className="itemRight">
                                        <div>
                                            {optionRight.map((item, index) => (
                                                <Field key={item.id} name={item.name} disabled={item.disabled} inputType={item.inputType} placeholder={I18n.getTranslation(location, item.placeholder)} inputClass={item.inputClass} icon={item.icon} component={InputField} />
                                            ))}
                                        </div>
                                        <div>
                                            {selectRight.map((item, index) => (
                                                <Field key={item.id} name={item.name} placeholder={I18n.getTranslation(location, item.placeholder)} options={item.options} menuClassName={item.menuClassName} inputClass={item.inputClass} icon={item.icon} component={InputSearchSelect} />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                {responseErr && <div className="submitError"><I18n t={responseErr} /></div>}
                                {submitError && <div className="submitError"><I18n t={submitError} /></div>}
                            </div>
                            <BottomBarRegistration buttonNextapprove={checkFields(values)} withBack={true} clickBack={() => form.change('formButton', 'back')} clickNext={() => form.change('formButton', 'next')} />
                        </div>
                    </form>
                )}

            </Form>

        </div >
    );
});

