import { FieldRenderProps } from "react-final-form";
import '../lib.scss';
import DatePicker from 'react-date-picker';
import React from "react";


type Props<T> = FieldRenderProps<T, HTMLInputElement>;

export const InputField = <T extends string | number>(props: Props<T>) => {
    const { meta, input } = props;
    const languageDirection=window.location.pathname.includes('he')||window.location.pathname.includes('ar')?'rtl':'ltr';
    var date = input.value && props.inputType === "date" ? new Date(input.value) : new Date();
    return <>
        <div className={props.inputClass ? props.inputClass : "inputText"}>
            {input.value && <div className={languageDirection==="rtl"?"smallPlaceholder smallPlaceholderRtl":"smallPlaceholder"}>{props.placeholder}</div>}
            <div className="flex ">
                <div className="inputItem">
                    {props.inputType === "date" ?
                        <DatePicker
                            className={input.value !== "" ? 'inputfiled' : 'inputfiled inputfiledBig'}
                            onChange={input.onChange}
                            value={date}
                        /> :
                        <input
                            id={input.value !== "" ? '' : 'inputfiled'}
                            className={props.inputSmallClass ? props.inputSmallClass : "inputfiled"}
                            // className={input.value !== "" ? "inputfiled" : "inputfiled2"}
                            // className={props.inputClass ? props.inputClass : "inputText"}
                            accept={props.inputAccept && props.inputAccept}
                            type={props.inputType}
                            value={input.value}
                            onChange={input.onChange}
                            disabled={props.disabled}
                            placeholder={props.placeholder}
                            style={props.style}
                            autoFocus={props.autoFocus}
                        />}</div>
                <div id={input.value !== "" ? 'iconPosition' : ''} className={"iconStyle inputItemRight"}>
                    {props.icon}</div>
            </div>
        </div>

        {(meta.touched && meta.error) || (meta.submitError &&
            <div className="submitError">{(meta.error) || (meta.submitError)}</div>)}
    </>
}
