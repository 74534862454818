import styles from '../../screens/landing-page/landing-page.module.scss';
import React from "react";
import { observer } from "mobx-react";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import arrowDown from "../../images/arrow-down.svg"
import { useHistory } from "react-router-dom";
export const savedLanguage = "selectedLanguage";

export type language = {
    name: string;
    code: string;
    img: string;
}
export const LanguageMenu = observer((props: any) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    var setLanguageDirection = props.setLanguageDirection;
    let languages: language[] = [
        { name: 'עברית', code: 'he', img: "https://purecatamphetamine.github.io/country-flag-icons/3x2/IL.svg" },
        { name: 'English', code: 'en', img: "https://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg" },
        { name: 'اللغة العربية', code: 'ar', img: "https://purecatamphetamine.github.io/country-flag-icons/3x2/AE.svg" },
        // { name: 'Magyar', code: 'hu', img: "https://purecatamphetamine.github.io/country-flag-icons/3x2/HU.svg" },
        { name: 'Ελληνικά', code: 'gr', img: "https://purecatamphetamine.github.io/country-flag-icons/3x2/GR.svg" },
        { name: 'Русский', code: 'ru', img: "https://purecatamphetamine.github.io/country-flag-icons/3x2/RU.svg" },
        { name: 'Español', code: 'es', img: "https://purecatamphetamine.github.io/country-flag-icons/3x2/ES.svg" },
        { name: 'Français', code: 'fr', img: "https://purecatamphetamine.github.io/country-flag-icons/3x2/FR.svg" },
        // { name: 'Deutsch', code: 'de', img: "https://purecatamphetamine.github.io/country-flag-icons/3x2/DE.svg" },
        // { name: 'Nederlands', code: 'nl', img: "https://purecatamphetamine.github.io/country-flag-icons/3x2/NL.svg" },
        { name: 'Português', code: 'pt', img: "https://purecatamphetamine.github.io/country-flag-icons/3x2/PT.svg" },


    ];
    var titleObj: { [key: string]: string } = {
        'he': 'Phone.do עבודה מהבית | האתר הרשמי',
        'en': 'Phone.do Official Site | Work From Home',
        'ar': '| الموقع الرسمي Phone.do العمل من المنزل',
        'hu': 'Phone.do hivatalos | Otthoni munkavégzés',
        'gr': 'Phone.do επίσημη ιστοσελίδα | Εργασία από το σπίτι',
        'ru': 'официальный сайт Phone.do | Работа из дома',
        'es': 'Phone.do Sitio Oficial | Trabajar desde casa',
        'fr': 'Phone.do Site officiel | Travail à domicile',
        'de': 'Offizielle Phonedo-Website | Von zuhause aus arbeiten',
        'nl': 'Officiële Phonedo-site | Thuiswerken',
        'pt': 'Site Oficial Phone.do | Trabalho a partir de casa'

    }

    let languagesCode: { [key: string]: string } = {
        'he': 'עברית',
        'en': 'English',
        'ar': 'اللغة العربية',
        'hu': 'Magyar',
        'gr': 'Ελληνικά',
        'ru': 'Русский',
        'es': 'Español',
        'fr': 'Français',
        'de': 'Deutsch',
        'nl': 'Nederlands',
        'pt': 'Português'
    }

    let history = useHistory();

    const handleChange = (event: any, name: any) => {
        setAnchorEl(null);
        history.push(`/${name}`);
        if (name === "he" || name === "ar") {
            setLanguageDirection("rtl")
        }
        else {
            setLanguageDirection("lts")
        }
        document.title = Object.values(titleObj)[Object.keys(titleObj).indexOf(name)];
        props.close&&props.close();

    };
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={`${props.right ? styles.langDivRtl : styles.langDiv}`}>
            <Button style={{ 'color': 'white' }} className="menu-item" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <span className={styles.langMenuTitle}>{languagesCode[window.location.pathname.slice(1, 3) as any]}</span>
                <img src={arrowDown} alt="arrowDown"></img>
            </Button>
            <Menu
                style={ props.right ? {'direction': 'rtl', 'zIndex': 70000, 'top': '50px' } : {'direction': 'ltr', 'zIndex': 70000, 'top': '50px' ,"width":"300px"}}
                className={props.right ?styles.langMenuRtl :styles.langMenu}
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose} >
                {languages.map(item => (
                    <MenuItem key={item.name} value={item.name} onClick={(e: any) => {
                        handleChange(e, item.code)
                    }} style={{ 'paddingInline': '8px' }}>
                        <div className={styles.menuCard} style={props.right ? {'direction': 'rtl'} : {'direction': 'ltr'}}>
                            <img alt="flag" width="17px" src={item.img} className="flagImg" />
                            <span className={styles.langMenuLabel}>{item.name}</span>
                        </div>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    )
})