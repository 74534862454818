import { Form } from "react-final-form";
import { TopBarRegistration } from "../../lib/final-form/top-bar-registration";
import './registration.scss'
import agentType1 from "../../images/agent-type-1.svg";
import agentType2 from "../../images/agent-type-2.svg";
import radioCheck from "../../images/radio-check.svg";
import radioUncheck from "../../images/radio-uncheck.svg";
import { BottomBarRegistration } from "../../lib/final-form/bottom-bar-registration";
import { useEffect, useState } from "react";
import banner3 from '../../images/banner3.png';
import { observer } from "mobx-react";
import { useAgentStore } from "../../stores/use-agent-store";
import { useHistory } from "react-router-dom";
import I18n from "../../lib/lingui/i18n";
import logoButton from "../../images/content.png"
import { EnumItemModel } from "../../model/authentication";



export const RegistrationAgentType = observer((props: any) => {
    const { registrationStore } = useAgentStore();
    let history = useHistory();
    const [fullPartAgent, setfullPartAgent] = useState(1);
    const [languageDirection, setLanguageDirection] = useState<string>();
    const [fullItem, setFullItem] = useState<EnumItemModel>();
    const [partItem, setPartItem] = useState<EnumItemModel>();

    useEffect(() => {
        if (!registrationStore.callAgentOnboarding)
            registrationStore.getCallAgentOnBoarding().then((res) => {
                var agentType1 = registrationStore.callAgentOnboarding?.jobType;
                agentType1?.name.toLocaleLowerCase() === 'full' ?
                    setfullPartAgent(1) : setfullPartAgent(0);
            })
        registrationStore.getEnumByKey('PhoneDo.CallAgent.JobType').then((res) => {
            if (res) {
                var fullType = res.items.filter((item: any) => item.name.toLowerCase() === 'full')[0];
                var partType = res.items.filter((item: any) => item.name.toLowerCase() === 'part')[0];
                setFullItem(fullType);
                setPartItem(partType);
            }
        })
        var agentType = registrationStore.callAgentOnboarding?.jobType;
        if (agentType) {
            agentType.name.toLocaleLowerCase() === 'full' ?
                setfullPartAgent(1) : setfullPartAgent(0);
        }
    }, [registrationStore])

    useEffect(() => {
        if (window.location.pathname.includes('he') || window.location.pathname.includes('ar')) {
            setLanguageDirection("rtl")
        }
        else {
            setLanguageDirection("lts")
        }
    }, []);
    return (
        <div>
            <Form
                onSubmit={async (form: any) => {
                    var type = fullPartAgent === 1 ? fullItem : partItem;
                    switch (form.formButton) {
                        case 'next': {
                            const response = await registrationStore.updateCallAgent({ timePositionType: type });
                            if (response && response.errors) {
                                return response;
                            }
                            return history.push(`/${window.location.pathname.slice(1, 3)}/registration/about-you`)
                        }
                        case 'back': {
                            return history.push(`/${window.location.pathname.slice(1, 3)}/registration/become-an-agent`)
                        }
                        case 'exit': {
                            const response = await registrationStore.updateCallAgent({ timePositionType: type });
                            if (response && response.errors) {
                                return response;
                            }
                            return history.push(`/${window.location.pathname.slice(1, 3)}`)
                        }
                    }
                }} >
                {({ submitError, handleSubmit, form }) => (
                    <form onSubmit={handleSubmit} className={languageDirection === "rtl" ? "registrationBodyRtl" : "registrationBody"}>
                        <img src={banner3} className="banner" id="banner3" alt={'banner3'} />
                        <img className={languageDirection === "rtl" ? "logoButtonRtl" : "logoButton"} src={logoButton} alt={'logoButton'} onClick={() => { history.push('/') }} />
                        <div className={languageDirection === "rtl" ? "formSideRtl" : "formSide"}>
                            <TopBarRegistration title={"Personal information"} subTitle={"Agent type"} withMenu={true} withExit={true} clickExit={() => form.change('formButton', 'exit')} />
                            <div className="dataAreaWide">
                                <div className="header agentTypeHeader"><I18n t="What type of agent will you be?" /></div>
                                <div className="descriptionSubHeader"><I18n t="Full-time positions help businesses contact you during business hours and days regularly A part-time position will give businesses the option to assist you and you can still keep your full-time day job" /></div>
                                <div className="agentTypeCardForm">
                                    <div className={languageDirection === "rtl" ? "agentTypeCard agentTypeCardRtl" : "agentTypeCard"} onClick={() => setfullPartAgent(1)}>
                                        <div className="agentTypeCardArea">
                                            <div className="item"><div className="header"><I18n t="Full-time position" /></div></div>
                                            <div className="item radioButton"><img className="checkIcon" alt="check input" src={fullPartAgent ? radioCheck : radioUncheck} /></div>
                                        </div>
                                        <div className="agentTypeCardArea">
                                            <img alt="agent full time" className="agentTypeImg" src={agentType1} />
                                            <div className="subHeader"><I18n t="Businesses will contact you during business hours and days regularly" /></div></div>
                                    </div>
                                    <div className={languageDirection === "rtl" ? "agentTypeCard agentTypeCardRtl" : "agentTypeCard"} onClick={() => setfullPartAgent(0)}>
                                        <div className="agentTypeCardArea">
                                            <div className="item"><div className="header"><I18n t="Part-time position" /></div></div>
                                            <div className="item radioButton"><img className="checkIcon" alt="check input" src={fullPartAgent ? radioUncheck : radioCheck} /></div></div>
                                        <div className="agentTypeCardArea">
                                            <img alt="agent part time" className="agentTypeImg" src={agentType2} />
                                            <div className="subHeader"><I18n t="Businesses will assist you and you can still keep your full-time day job" /></div></div>
                                    </div>
                                </div>
                                {submitError && <div className="submitError"><I18n t={submitError} /></div>}
                            </div>
                            <BottomBarRegistration buttonNextapprove="approve" withBack={true} clickBack={() => form.change('formButton', 'back')} clickNext={() => form.change('formButton', 'next')} />
                        </div>
                    </form>
                )}

            </Form>

        </div >
    );
});

