import '../lib.scss'
import React from 'react'
import I18n from '../lingui/i18n'

export const BottomBarRegistration = (props: any) => {

    return <>
        <div className="bottomBarRegistration">
            {props.withBack === true && <div className="item"><button className="bottomBarRegistrationButton" onClick={props.clickBack}><I18n t="Back"/></button></div>}
            <div className="item itemButton">
            {props.nextTitle  ? <button className="bottomBarRegistrationButton" id={props.buttonNextapprove === "approve" ? "buttonNextApprove" : "buttonNextUnapprove"} onClick={props.clickNext}>{props.nextTitle}</button>
            :<button className="bottomBarRegistrationButton" id={props.buttonNextapprove === "approve" ? "buttonNextApprove" : "buttonNextUnapprove"} onClick={props.buttonNextapprove === "approve" ? props.clickNext: null}><I18n t="Next"/></button>}
            </div>
        </div>
    </>
}
