import { Field, Form } from "react-final-form";
import { BottomBarRegistration } from "../../lib/final-form/bottom-bar-registration";
import { InputField } from "../../lib/final-form/input-field";
import { InputSearchSelect } from "../../lib/final-form/select-search";
import { TopBarRegistration } from "../../lib/final-form/top-bar-registration";
import { RegistrationCertificatesForm } from "../../stores/registration-store";
import { observer } from "mobx-react";
import banner12 from '../../images/banner12.png';
import './registration.scss';
import { useAgentStore } from "../../stores/use-agent-store";
import { useEffect, useState } from "react";
import { FaRegCalendarAlt, FaFilePdf } from 'react-icons/fa';
import { FiUpload } from 'react-icons/fi';
import { useHistory } from "react-router-dom";
import I18n from "../../lib/lingui/i18n";
import logoButton from "../../images/content.png"

export const RegistrationCertificates = observer((props: any) => {
    const { registrationStore } = useAgentStore();
    const { location } = props;
    const [image, setImage] = useState({ preview: "", raw: "" });
    const [file, setFile] = useState<any>();
    const [fileType, setFileType] = useState<string>("");
    const [languageDirection, setLanguageDirection] = useState<string>();
    let subjectListOptions: { label: string, value: string }[] = [];
    var responseErr = '';

    registrationStore.subjectList?.reduce((obj, item) => {
        subjectListOptions.push({
            label: item.name,
            value: item.id,
        });
        return {}
    }, {});
    let history = useHistory();
    const optionLeft: { id: number, name: string, "disabled": string, inputType: string, placeholder: string, inputClass: string, icon: any }[] = [
        { "id": 1, "name": "jurisdiction", "disabled": "", "inputType": "text", "placeholder": "Jurisdiction area", "inputClass": "smallInputText", "icon": null, },
    ];
    const optionRight: {
        id: number, name: string, "disabled": string, inputType: string, placeholder: string, inputClass: string, icon: any, value: any
    }[] = [
            { "id": 2, "name": "expirationDate", "disabled": "", "inputType": "date", "placeholder": "Expiration date", "inputClass": "smallInputText", "icon": <FaRegCalendarAlt />, "value": "" },
        ];
    const selectLeft: { id: number, name: string, placeholder: string, inputClass: string, options: { label: string, value: string }[], menuClassName: string, icon: any }[] = [
        { "id": 3, "name": "sagment", "placeholder": "Sagment", "inputClass": "smallInputText", options: subjectListOptions, "menuClassName": "countrySelect", "icon": null },
    ];
    const selectRight: {
        id: number, name: string, placeholder: string, inputClass: string, options: { label: string, value: string }[], menuClassName: string, icon: any
    }[] = [
        ];

    useEffect(() => {
        if (window.location.pathname.includes('he') || window.location.pathname.includes('ar')) {
            setLanguageDirection("rtl")
        }
        else {
            setLanguageDirection("lts")
        }
    }, []);
    const handleChange = (e: any) => {
        if (e.target.files.length) {
            var file = (e.target.files[0]);
            const fsize = e.target.files[0].size;
            const file1 = Math.round((fsize / 1024));
            if (file1 >= 800) {
                responseErr= 'File too Big, please select a file less than 800kb' ;
                // alert(
                //     "File too Big, please select a file less than 800kb");
            } else {
                setFile(file);

                setImage({
                    preview: URL.createObjectURL(e.target.files[0]),
                    raw: e.target.files[0]
                });
                setFileType(e.target.files[0].type);
            }

        }
    };
    function checkFields(form: any) {
       if(!form.expirationDate)
       form.expirationDate=new Date()
        if ((form.jurisdiction && form.expirationDate && form.sagment && image.preview) || (!form.jurisdiction && !form.sagment && !image.preview)) {
            return "approve"
        }
        return null
    }
    useEffect(() => {
        if (!registrationStore.callAgentOnboarding)
            registrationStore.getCallAgentOnBoarding().then(() => registrationStore.fetchCertificationByUserId(registrationStore?.callAgentOnboarding?.id).then(async () => {
                if (registrationStore.registrationCertificatesForm !== null) {
                    setImage({ preview: registrationStore?.registrationCertificatesForm?.file ?? "", raw: "" });
                    const blob = await fetch(registrationStore?.registrationCertificatesForm?.file ?? "").then((r) => r.blob());
                    setFile(blob);
                    var fileName1 = "";
                    fileName1 = registrationStore?.registrationCertificatesForm?.file.replace(/^.*[\\/]/, '') ?? "";
                    var fileType1 = "";
                    fileType1 = fileName1.split('.').pop() ?? "";
                    setFileType(fileType1);
                }
            }));
        registrationStore.getEnumByKey('PhoneDo.CallAgent.Profession').then((res) => {
            if (res)
                registrationStore.subjectList = res.items;
        })
    }, [registrationStore])
    return (
        <div>
            <Form<RegistrationCertificatesForm>
                initialValues={registrationStore.registrationCertificatesForm}
                onSubmit={async (form: any) => {
                    switch (form.formButton) {
                        case 'next': {
                            if (!form.jurisdiction && !form.sagment && !image.preview) {
                                return history.push(`/${window.location.pathname.slice(1, 3)}/registration/education-title`)
                            } else {
                                const today = new Date();
                                let tomorrow = new Date()
                                tomorrow.setDate(today.getDate() + 1);
                                if (!form.expirationDate ||form.expirationDate < tomorrow) {
                                         responseErr= 'Expiration date should be in the future' ;
                                    return;
                                }
                                else {
                                    if (!registrationStore.registrationCertificatesForm) {
                                        const response1 = await registrationStore.uploadCertification(registrationStore?.callAgentOnboarding?.id, file, fileType, form.expirationDate, form.jurisdiction, form.sagment.value);
                                        if (response1 && response1.errors) {
                                            return response1;
                                        }
                                    }
                                    else {
                                        const response2 = await registrationStore.updateCertification(registrationStore?.callAgentOnboarding?.id, form.id, file, fileType, form.expirationDate, form.jurisdiction, form.sagment.value);
                                        if (response2 && response2.errors) {
                                            return response2;
                                        }
                                    }
                                    return history.push(`/${window.location.pathname.slice(1, 3)}/registration/education-title`)
                                }
                            }
                        }
                        case 'back': {
                            return history.push(`/${window.location.pathname.slice(1, 3)}/registration/education-degree`)
                        }
                        case 'exit': {
                            return history.push(`/${window.location.pathname.slice(1, 3)}`)
                        }
                    }
                }} >
                {({ submitError, handleSubmit, form, values }) => (
                    <form onSubmit={handleSubmit} className={languageDirection === "rtl" ? "registrationBodyRtl" : "registrationBody"}>
                        <img src={banner12} className="banner" id="banner12" alt={'banner12'} />
                        <img className={languageDirection === "rtl" ? "logoButtonRtl" : "logoButton"} src={logoButton} alt={'logoButton'} onClick={() => { history.push('/') }} />
                        <div className={languageDirection === "rtl" ? "formSideRtl" : "formSide"}>
                            <TopBarRegistration title="Professional information" subTitle="Certificates" withMenu={true} withExit={true} clickExit={() => form.change('formButton', 'exit')} />
                            <div className="dataAreaWide">
                                <div className="header"><I18n t="Add Certificates" /></div>
                                <div className="subHeader">
                                    <I18n t="Fill free to share your certificates of any kind that you think will benefit the business"></I18n>
                                </div>
                                <div className="flexForm">
                                    <div >
                                        <div style={{ textAlign: 'center', marginBottom: "2.5vh" }} className={languageDirection === "rtl" ? "marginLeftRtl" : ""}>
                                            {selectLeft.map((item, index) => (
                                                <Field key={item.id} name={item.name} placeholder={I18n.getTranslation(location, item.placeholder)} options={item.options} menuClassName={item.menuClassName} inputClass={item.inputClass} icon={item.icon} component={InputSearchSelect} />
                                            ))}
                                        </div>
                                        <div>
                                        </div>
                                        <div>
                                            {optionLeft.map((item, index) => (
                                                <Field key={item.id} name={item.name} disabled={item.disabled} inputType={item.inputType} placeholder={item.placeholder} inputClass={item.inputClass} icon={item.icon} component={InputField} />
                                            ))}
                                        </div>
                                    </div>
                                    <div className="itemRight">
                                        <div>
                                            {optionRight.map((item, index) => (
                                                <Field key={item.id} name={item.name} disabled={item.disabled} inputType={item.inputType} placeholder={I18n.getTranslation(location, item.placeholder)} inputClass={item.inputClass} icon={item.icon} value="" component={InputField} />
                                            ))}
                                        </div>
                                        <div>
                                            {selectRight.map((item, index) => (
                                                <Field key={item.id} name={item.name} placeholder={I18n.getTranslation(location, item.placeholder)} options={item.options} menuClassName={item.menuClassName} inputClass={item.inputClass} icon={item.icon} component={InputSearchSelect} />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ height: "32px" }}></div>
                                <div style={{ display: "flex" }}>
                                    <div style={{ height: '93px', width: '99px', }}>
                                        <label htmlFor="upload-button">
                                            <>
                                                <div style={{ cursor: 'pointer', display: 'grid', gridTemplateColumns: '1fr', gridTemplateRows: '1fr', gridArea: 'overlap', }}>
                                                    <span style={{
                                                        color: 'lightgrey',
                                                        height: '90px',
                                                        width: '90px',
                                                        backgroundImage: 'none', gridArea: 'overlap',
                                                        borderWidth: '1px',
                                                        borderStyle: 'solid',
                                                        borderColor: '#C5C7D1',
                                                        borderRadius: '8% 8% 8% 8%',
                                                        textAlign: 'center',
                                                        verticalAlign: 'middle',
                                                        lineHeight: '90px',
                                                    }}>
                                                        {!image.preview ? (<FiUpload
                                                            style={{
                                                                color: 'lightgrey',
                                                                fontSize: '40px', backgroundImage: 'none', gridArea: 'overlap',
                                                                textAlign: 'center',
                                                                verticalAlign: 'middle',
                                                            }} />) : fileType === "pdf" || fileType === "application/pdf" ? (<FaFilePdf
                                                                style={{
                                                                    color: 'salmon',
                                                                    fontSize: '60px', backgroundImage: 'none', gridArea: 'overlap',
                                                                    textAlign: 'center',
                                                                    verticalAlign: 'middle',
                                                                }} />) : (
                                                            <img src={image.preview} alt="uploadImg" style={{
                                                                color: "#F6F5FB", height: '60px', width: '60px', gridArea: 'overlap', fontSize: '90px', backgroundImage: 'none',
                                                                textAlign: 'center',
                                                                verticalAlign: 'middle',
                                                                borderRadius: '8% 8% 8% 8%',
                                                            }} />
                                                        )}
                                                    </span>
                                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr', gridTemplateRows: '1fr', gridArea: 'overlap', }}>
                                                    </div>
                                                </div>

                                            </>
                                        </label>
                                        <input
                                            type="file"
                                            id="upload-button"
                                            style={{ display: "none" }}
                                            onChange={handleChange}
                                            accept="image/png,  image/jpeg, image/jpg, application/pdf"
                                        />
                                    </div>
                                    <div style={{ paddingLeft: "5px", paddingTop: "30px" }} className="topBarRegistrationTitle"><I18n t="Upload files of your certificates so we can verify your training" /></div>
                                </div>
                                <br />
                                <div className="subHeader" ><I18n t="Accepted file types jpg jpeg png pdf only File size no more then 800kb"></I18n></div>
                                {responseErr && <div className="submitError"><I18n t={responseErr} /></div>}
                                {submitError && <div className="submitError"><I18n t={submitError} /></div>}

                            </div>
                            <BottomBarRegistration buttonNextapprove={checkFields(values)} withBack={true} clickBack={() => form.change('formButton', 'back')} clickNext={() => form.change('formButton', 'next')} />
                        </div>
                    </form>
                )}
            </Form>
        </div >
    );
});
