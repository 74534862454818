import { FieldRenderProps } from "react-final-form";
import '../lib.scss';
import React from "react";


type Props<T> = FieldRenderProps<T, HTMLInputElement>;

export const InputTextArea = <T extends string | number>(props: Props<T>) => {
    const { meta, input } = props;
    const languageDirection=window.location.pathname.includes('he')||window.location.pathname.includes('ar')?'rtl':'ltr';

    return <>
        <div className={props.inputClass ? props.inputClass : "inputText"}>
            {input.value && <div className={languageDirection==="rtl"?"smallPlaceholder smallPlaceholderRtl":"smallPlaceholder"}>{props.placeholder}</div>}
            <textarea value={input.value} placeholder={props.placeholder} onChange={input.onChange} className="inputTextAreafiled" name="w3review" rows={5} cols={50} />
            {props.icon}
        </div>
        {(meta.touched && meta.error) || (meta.submitError &&
            <div className="submitError">{(meta.error) || (meta.submitError)}</div>)}
    </>
}
