import { Field, Form } from "react-final-form";
import { BottomBarRegistration } from "../../lib/final-form/bottom-bar-registration";
import { TopBarRegistration } from "../../lib/final-form/top-bar-registration";
import { RegistrationLanguageForm } from "../../stores/registration-store";
import AddIcon from "../../images/add-icon.svg"
import './registration.scss'
import { useEffect, useState } from "react";
import banner6 from '../../images/banner6.png';
import { observer } from "mobx-react";
import { useAgentStore } from "../../stores/use-agent-store";
import { useHistory } from "react-router-dom";
import { FaTrashAlt } from 'react-icons/fa';
import I18n from "../../lib/lingui/i18n";
import logoButton from "../../images/content.png"


import React from "react";
import { Accent } from "../../model/authentication";
import { toJS } from "mobx";
import { InputSelectCountry } from "../../lib/final-form/input-selectCountry";


export const RegistrationLanguage = observer((props: any) => {
    const { registrationStore } = useAgentStore();
    let history = useHistory();
    const [language, setLanguage] = useState(1);
    const [languageDirection, setLanguageDirection] = useState<string>();
    const [languageList, setLanguageList] = useState<Array<{ label: string, value: string }>>([]);
    const [languageStrList, setLanguageStrList] = useState<Array<string>>([]);
    const [accentList, setAccentList] = useState<Array<Array<Accent>>>([]);
    const { location } = props;

    useEffect(() => {
        if (window.location.pathname.includes('he') || window.location.pathname.includes('ar')) {
            setLanguageDirection("rtl")
        }
        else {
            setLanguageDirection("lts")
        }
    }, []);

    useEffect(() => {
        if (!registrationStore.languagesList) {
            registrationStore.fetchLanguages().then(async () => {
                if (registrationStore.languagesList) {
                    var languagesStrNames: Array<string> = [];
                    var languagesNames: Array<{ label: string; value: string; }> = [];
                    var languagesAccents: Array<Accent[]> = [];
                    registrationStore.languagesList.forEach((item) => {
                        languagesStrNames.push(item.title)
                        languagesNames.push({ label: item.title, value: item.id })
                        languagesAccents.push(toJS(item.accents))
                    })
                    setLanguageStrList(languagesStrNames)
                    setLanguageList(languagesNames)
                    setAccentList(languagesAccents)

                }
            })
        } else {
            var languagesStrNames: Array<string> = [];
            var languagesNames: Array<{ label: string; value: string; }> = [];
            var languagesAccents: Array<Accent[]> = [];
            registrationStore.languagesList.forEach((item) => {
                languagesStrNames.push(item.title)
                languagesNames.push({ label: item.title, value: item.id })
                languagesAccents.push(toJS(item.accents))
                setLanguageStrList(languagesStrNames)
                setLanguageList(languagesNames)
                setAccentList(languagesAccents)
            })
        }
        if (!registrationStore.callAgentOnboarding)
            registrationStore.getCallAgentOnBoarding()
                .then(() => {
                    var languages1 = registrationStore.callAgentOnboarding?.languages;
                    if (languages1 && languages1.length > 0) {
                        setLanguage(languages1.length);
                    }
                })
        var languages = registrationStore.callAgentOnboarding?.languages;
        if (languages && languages.length > 0) {
            setLanguage(languages.length);
        }
    }, [registrationStore])

    async function createCallAgentOnBoarding(form: any) {
        var list = [];
        var obj1 = {
            'name': form.language?.value ?? form.language,
            'accent': form.accent?.value ?? form.accent,
        }
        list.push(obj1);
        if (language === 2) {
            var obj2 = {
                'name': form.language2?.value ?? form.language2,
                'accent': form.accent2?.value ?? form.accent2,
            }
            list.push(obj2);
        }
        else if (language === 3) {
            var obj31 = {
                'name': form.language2?.value ?? form.language2,
                'accent': form.accent2?.value ?? form.accent2,
            }
            list.push(obj31);
            var obj32 = {
                'name': form.language3?.value ?? form.language3,
                'accent': form.accent3?.value ?? form.accent3,
            }
            list.push(obj32);
        }
        const response = await registrationStore.updateCallAgentLanguages(list);
        return response;
    }

    function checkFields(form: any) {
        if (form.language && form.accent && form.language.value && form.accent.value) {
            return "approve"
        }
        return null
    }
    function removeLanguageFromList(form: any, values: any) {
        if (language === 2) {
            form.change('language2', undefined);
            form.change('accent2', undefined);
            setLanguage(language - 1)
        }
        else if (language === 3) {
            form.change('language2', values.language3);
            form.change('language3', undefined);
            form.change('accent2', values.accent3);
            form.change('accent3', undefined);
            setLanguage(language - 1)
        }
    }

    function getAccentList(language: any | string) {
        if (typeof (language) == 'object') {
            var accents = accentList[languageStrList.indexOf(language.label)];
            if (accents) {
                var accentObj = accents.map((item) => ({ label: item.title, value: item.id }))
                return accentObj;
            }
        } else if (typeof (language) == 'string' && language && language.trim() !== '') {
            var accents2 = accentList[languageStrList.indexOf(language)];
            if (accents2) {
                var accentObj2 = accents2.map((item) => ({ label: item.title, value: item.id }))
                return accentObj2;
            }
        }
        return [];
    }

    return (
        <div>
            <Form<RegistrationLanguageForm>
                initialValues={registrationStore.registrationLanguageForm}
                onSubmit={async (form: any) => {
                    switch (form.formButton) {
                        case 'next': {
                            const response = await createCallAgentOnBoarding(form);
                            if (response && response.errors) {
                                return response;
                            }
                            return history.push(`/${window.location.pathname.slice(1, 3)}/registration/social-media`)
                        }
                        case 'back': {
                            return history.push(`/${window.location.pathname.slice(1, 3)}/registration/short-description`)
                        }
                        case 'exit': {
                            const response = await createCallAgentOnBoarding(form);
                            if (response && response.errors) {
                                return response;
                            }
                            return history.push(`/${window.location.pathname.slice(1, 3)}`)
                        }
                    }
                }} >
                {({ submitError, handleSubmit, form, values }) => (
                    <form onSubmit={handleSubmit} className={languageDirection === "rtl" ? "registrationBodyRtl" : "registrationBody"}>
                        <img src={banner6} className="banner" id="banner6" alt={'banner6'} />
                        <img className={languageDirection === "rtl" ? "logoButtonRtl" : "logoButton"} src={logoButton} alt={'logoButton'} onClick={() => { history.push('/') }} />
                        <div className={languageDirection === "rtl" ? "formSideRtl" : "formSide"}>
                            <TopBarRegistration title="Language" subTitle="Choose language & accent" withMenu={true} withExit={true} clickExit={() => form.change('formButton', 'exit')} />
                            <div className="dataAreaWide">
                                <div className="header"><I18n t="Choose language & accent" /></div>
                                <div className="subHeader"><I18n t="You can select up to 3 languages, All languages you choose should be at the level of writing, reading and speaking" /></div>
                                <div className="flexForm">
                                    <div className={languageDirection === "rtl" ? "languageFieldsRtl" : "languageFields"}>
                                        <div className="fieldLanguage">
                                            <Field name={"language"} placeholder={I18n.getTranslation(location, "Language")} options={languageList} inputClass="smallInputText" menuClassName={'countrySelect'} component={InputSelectCountry} />
                                        </div>
                                    </div>
                                    <div className="itemRight">
                                        <div className="fieldLanguage">
                                            <Field name={"accent"} placeholder={I18n.getTranslation(location, "Accent")} options={getAccentList(values.language)} inputClass="smallInputText" menuClassName={'countrySelect'} component={InputSelectCountry} />
                                        </div>
                                    </div>
                                </div>


                                {submitError && <div className="submitError"><I18n t={submitError} /></div>}
                                <div><I18n t="Add additional language" /><img onClick={() => { if (language < 3) setLanguage(language + 1) }} className="addIcon" alt="add" src={AddIcon} /></div>
                                <br />
                                <br />
                                <div className="flexForm">
                                    {language === 2 ?
                                        <div className="languageFields">
                                            <div className="fieldLanguage">
                                                <Field name={"language2"} placeholder={I18n.getTranslation(location, "Language")} options={languageList} inputClass="smallInputText" menuClassName={'countrySelect'} component={InputSelectCountry} />
                                            </div>
                                        </div>
                                        : language === 3 ?
                                            <div className="languageFields" >
                                                <div className="fieldLanguage">
                                                    <Field name={"language2"} placeholder={I18n.getTranslation(location, "Language")} options={languageList} inputClass="smallInputText" menuClassName={'countrySelect'} component={InputSelectCountry} />
                                                </div>
                                                <div className="fieldLanguage ">
                                                    <Field name={"language3"} placeholder={I18n.getTranslation(location, "Language")} options={languageList} inputClass="smallInputText" menuClassName={'countrySelect'} component={InputSelectCountry} />
                                                </div>
                                            </div> : null
                                    }
                                    {language === 2 ?
                                        <div className="itemRight">
                                            <div className="fieldLanguage">
                                                <Field name={"accent2"} placeholder={I18n.getTranslation(location, "Accent")} options={getAccentList(values.language2)} inputClass="smallInputText" menuClassName={'countrySelect'} component={InputSelectCountry} />
                                            </div>
                                        </div> :
                                        language === 3 ? <div>
                                            <div className="itemRight">
                                                <div className="fieldLanguage">
                                                    <Field name={"accent2"} placeholder={I18n.getTranslation(location, "Accent")} options={getAccentList(values.language2)} inputClass="smallInputText" menuClassName={'countrySelect'} component={InputSelectCountry} />
                                                </div>
                                                <div className="fieldLanguage">
                                                    <Field name={"accent3"} placeholder={I18n.getTranslation(location, "Accent")} options={getAccentList(values.language3)} inputClass="smallInputText" menuClassName={'countrySelect'} component={InputSelectCountry} />
                                                </div>

                                            </div>
                                        </div>
                                            : null
                                    }
                                    {language === 2 ?
                                        <div className="itemRight">
                                            <div className="fieldLanguage deleteLanguageIcon">
                                                <FaTrashAlt color="rgb(131, 126, 126)" className="faTrashAltIcon" size="25" onClick={() => { removeLanguageFromList(form, values) }} />
                                            </div>
                                        </div> :
                                        language === 3 ? <div>
                                            <div className="itemRight">
                                                <div className="fieldLanguage deleteLanguageIcon">
                                                    <FaTrashAlt color="rgb(131, 126, 126)" className="faTrashAltIcon" size="25" onClick={() => removeLanguageFromList(form, values)} />
                                                </div>
                                                <div className="fieldLanguage deleteLanguageIcon">
                                                    <FaTrashAlt color="rgb(131, 126, 126)" className="faTrashAltIcon" size="25" onClick={() => { removeLanguageFromList(form, values) }} />
                                                </div>

                                            </div>
                                        </div>
                                            : null
                                    }
                                </div>
                            </div>
                            <BottomBarRegistration buttonNextapprove={checkFields(values)} withBack={true} clickBack={() => form.change('formButton', 'back')} clickNext={() => form.change('formButton', 'next')} />
                        </div>
                    </form>
                )}

            </Form>

        </div >
    );
});

