import { Form } from "react-final-form";
import './registration.scss'
import banner3 from '../../images/banner3.png';
import dots2 from "../../images/dots2.svg"
import { TopBarRegistration } from "../../lib/final-form/top-bar-registration";
import { observer } from "mobx-react";
import { BottomBarRegistration } from "../../lib/final-form/bottom-bar-registration";
import { useHistory } from "react-router-dom";
import I18n from "../../lib/lingui/i18n";
import logoButton from "../../images/content.png"
import { useEffect, useState } from "react";
import { useAgentStore } from "../../stores/use-agent-store";


export const RegistrationBecomeAnAgent = observer((props: any) => {
    const { registrationStore } = useAgentStore();
    const { location } = props;
    const [languageDirection, setLanguageDirection] = useState<string>();
    let history = useHistory();

    useEffect(() => {
        if (window.location.pathname.includes('he') || window.location.pathname.includes('ar')) {
            setLanguageDirection("rtl")
        }
        else {
            setLanguageDirection("lts")
        }
    }, [registrationStore]);
    return (
        <div>
            <Form
                onSubmit={async (form: any) => {
                    switch (form.formButton) {
                        case 'next':
                            return history.push(`/${window.location.pathname.slice(1, 3)}/registration/agent-type`)
                        case 'exit':
                            return history.push(`/${window.location.pathname.slice(1, 3)}`)
                    }
                }} >
                {({ submitError, handleSubmit, form }) => (
                    <form onSubmit={handleSubmit} className={languageDirection === "rtl" ? "registrationBodyRtl" : "registrationBody"}>
                        <img src={banner3} className="banner" id="banner3" alt={'banner3'} />
                        <img className={languageDirection === "rtl" ? "logoButtonRtl" : "logoButton"} src={logoButton} alt={'logoButton'} onClick={() => { history.push('/') }} />
                        <div className="flexFormArea">
                            <TopBarRegistration withExit={true} clickExit={() => form.change('formButton', 'exit')} />
                            <div className={languageDirection === "rtl" ? "dataAreaCenRtlNoPadding formSideRtl" : "formSide"}>
                                <img className={languageDirection === "rtl" ? "dots2-small dots2-smallRtl" : "dots2-small"} src={dots2} alt={dots2} />
                                <div className="bigHeader paddingTitle"><I18n t="Become an Agent in a few easy steps" /></div>
                                <div className="subHeader" style={{ textAlign: "center" }}><I18n t="Join us We'll help you every step of the way" /></div>
                            </div>
                            <BottomBarRegistration buttonNextapprove="approve" nextTitle={I18n.getTranslation(location, "Let’s go!")} clickNext={() => form.change('formButton', 'next')} />
                        </div>
                    </form>
                )}

            </Form>

        </div >
    );
});

