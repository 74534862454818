import { FieldRenderProps } from "react-final-form";
import styles from './form.module.scss';
import I18n from "../lingui/i18n";


type Props<T> = FieldRenderProps<T, HTMLInputElement>;

export const RadioButton = <T extends string | number>(props: Props<T>) => {

    var options : Array<JSX.Element> = []

    for (let index = 0; index < props.options.length; index++) {
        var item1 = props.options[index];
        var item2 = null;

        if (index + 1 < props.options.length) {
            item2 = props.options[index + 1];
        }

        var element = <div key={"radio"+index} className={styles.radioDiv}>
            <div className={styles.radioItem}>
                <input type="radio" value={item1.value} name={props.radioName} id={`${props.radioName}${item1.value}`} onChange={props.onValueChange} checked={props.selectedItem === item1.value}/>
                <label htmlFor={`${props.radioName}${item1.value}`}><I18n t={item1.label}></I18n></label>
            </div>
            {item2 !== null ? <div className={styles.radioItem}>
                <input type="radio" value={item2.value} name={props.radioName} id={`${props.radioName}${item2.value}`} onChange={props.onValueChange} checked={props.selectedItem === item2.value}/>
                <label htmlFor={`${props.radioName}${item2.value}`}><I18n t={item2.label}></I18n></label>
            </div> : <></>}
        </div>
        options.push(element)
        index++
    }
    return <>
        {options.map((item: any, i: any) => {
            return item;
        })}
    </>
}