import {  useEffect, useState } from 'react';
import styles from './landing-page.module.scss';
import I18n from "../../lib/lingui/i18n";
import { useHistory } from 'react-router';


import logoMobile from "../../images/landing-page/logo-mobile.png";
import { LanguageMenu } from '../../lib/final-form/languageMenu';
import topBarImg from "../../images/landing-page/topbar-img.png";
import closeIcon from "../../images/landing-page/close-icon.svg";
import arrowDown from "../../images/arrow-down.svg"

export const TopMenu = ((props: any) => {
    const [languageDirection, setLanguageDirection] = useState<string>();
    let history = useHistory()

    useEffect(() => {
        if (window.location.pathname.includes('he') || window.location.pathname.includes('ar')) {
            setLanguageDirection("rtl")
        }
        else {
            setLanguageDirection("ltr")
        }
    }, [])

    return (
        <div className={styles.topbarMenu}>
            <div id="topbar" className={languageDirection === "rtl" ? styles.topbar.concat(" ").concat(styles.topbarRtl) : styles.topbar}>
                <div style={{ 'position': 'relative' }} className={styles.flexBetween}>
                    <div style={{ 'position': 'relative' }} className={styles.logo}><img src={logoMobile} alt="logo" className={styles.logo1} /></div>
                    <div style={{ 'position': 'relative' }} >
                    <div className={styles.menuIcon} onClick={()=>{props.close()}}><img alt="menu" src={closeIcon}/></div>
                    </div>
                </div>
            </div>
            <div className={styles.topbarbody}>
                <div className={styles.topLang}>
                    <LanguageMenu close={props.close} right={languageDirection === "rtl" ? true : false} setLanguageDirection={setLanguageDirection}></LanguageMenu>
                    <div className={styles.topbarItemMobileDiv} style={{ marginTop: '20px', textAlign: `${languageDirection === "rtl" ? "right" : "left"}` }}>
                        <a className={`${styles.topbarItemMobile}`} href={`http://phone.do`}>
                            <span className={styles.topbarItemText}><I18n t="Brands" /></span>
                            <img src={arrowDown} alt="arrowDown"></img>
                        </a>
                    </div>
                    <div className={styles.topbarItemMobileDiv} style={{ textAlign: `${languageDirection === "rtl" ? "right" : "left"}` }}>
                        <a className={`${styles.topbarItemMobile}`} href={`./${window.location.pathname.slice(1, 3)}`} >
                            <span className={styles.topbarItemText}><I18n t="Agents" /></span>
                            <img src={arrowDown} alt="arrowDown"></img>
                        </a>
                    </div>
                    <div className={styles.topbarItemMobileDiv} style={{ textAlign: `${languageDirection === "rtl" ? "right" : "left"}` }}>
                        <a className={`${styles.topbarItemMobile}`} href={`http://wp.phone.do/?page_id=3562`}><span className={styles.topbarItemText}><I18n t="Let's Talk" /></span></a>
                    </div>
                </div>
                <img alt="women" src={topBarImg} style={{"position":'absolute',"bottom":"7px"}}/>
            </div>
            <div id="bottombar" className={languageDirection === "rtl" ? styles.bottombar: styles.bottombar.concat(" ").concat(styles.bottombarRtl) }>
                    <button className={styles.signinBtn} onClick={() => { history.push(`/${window.location.pathname.slice(1, 3)}/registration/verify-phone/`) }}><I18n t="Register"/></button>
            </div>
        </div>
    );
})