
export const MuteFill = () => {
    return (
<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.6952 10.214C25.4822 10.1745 25.2633 10.1788 25.052 10.2266C24.8407 10.2745 24.6413 10.3649 24.4661 10.4923C24.2909 10.6198 24.1435 10.7816 24.0328 10.9679C23.9222 11.1541 23.8507 11.361 23.8226 11.5759C23.484 13.7636 22.4584 15.787 20.8941 17.3535C19.3297 18.92 17.3077 19.9484 15.1204 20.29L11.9064 24.7892V27.2371H15.311V23.6623C18.2701 23.2949 21.0252 21.9613 23.149 19.8683C25.2728 17.7753 26.6465 15.0399 27.0571 12.0865C27.1118 11.6596 27.0013 11.2279 26.7481 10.8798C26.4949 10.5316 26.1182 10.2935 25.6952 10.214Z" fill="white"/>
<path d="M11.0892 20.0755C9.14909 19.5925 7.39698 18.542 6.05673 17.0584C4.71649 15.5747 3.84896 13.7252 3.56497 11.7461C3.53761 11.5087 3.46215 11.2794 3.3432 11.0721C3.22425 10.8649 3.06431 10.684 2.87314 10.5407C2.68196 10.3973 2.46357 10.2944 2.2313 10.2382C1.99902 10.1821 1.75774 10.1738 1.52218 10.214C1.09913 10.2935 0.722463 10.5317 0.469286 10.8798C0.216109 11.2279 0.105578 11.6596 0.160322 12.0866C0.520039 14.5297 1.53932 16.8284 3.10842 18.7352C4.67753 20.6421 6.73702 22.0848 9.06518 22.9082L11.0892 20.0755Z" fill="white"/>
<path d="M13.2921 16.9908L20.418 7.01523V6.80925C20.418 5.00332 19.7006 3.27136 18.4236 1.99438C17.1466 0.717401 15.4146 0 13.6087 0C11.8027 0 10.0708 0.717401 8.79378 1.99438C7.51679 3.27136 6.79939 5.00332 6.79939 6.80925V10.2139C6.80579 11.9607 7.48327 13.6382 8.69171 14.8995C9.90015 16.1609 11.5471 16.9096 13.2921 16.9908Z" fill="white"/>
<path d="M5.09707 27.2371C4.78446 27.2369 4.47797 27.1505 4.21119 26.9875C3.94442 26.8246 3.72767 26.5913 3.58471 26.3133C3.44176 26.0353 3.37811 25.7233 3.40075 25.4115C3.42339 25.0997 3.53145 24.8002 3.71308 24.5458L20.7363 0.71341C20.9986 0.345904 21.3962 0.0976527 21.8415 0.0232685C22.2869 -0.0511157 22.7436 0.0544603 23.1111 0.316771C23.4786 0.579082 23.7268 0.97664 23.8012 1.42199C23.8756 1.86733 23.77 2.32399 23.5077 2.6915L6.48446 26.5239C6.3268 26.7447 6.11862 26.9247 5.87728 27.0488C5.63594 27.1729 5.36843 27.2374 5.09707 27.2371Z" fill="white"/>
</svg>

);
};
